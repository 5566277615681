<template>
  <div>
    <div>
      <template>
        <button class="ma-2" outlined color="green" v-bind="attrs" v-on="on">
          Kunde anlegen
        </button>
      </template>
      <form
        id="new_customer_form"
        name="new_customer_form"
        @submit.prevent="submit"
        netlify
        class="bg-white p-4"
      >
        <h2>Neuer Kunde</h2>
        <label
          >Kundennummer *
          <input
            aria-required="true"
            required
            type="number"
            v-model="customer.customernumber"
          />
        </label>
        <label>
          Firma *
          <input type="text" required v-model="customer.company" />
        </label>
        <label>
          Kundengruppe *
          <select required v-model="customer.customergroup">
            <option
              v-bind:key="item"
              v-bind:value="item"
              v-for="item in customergroups"
              >{{ item }}</option
            >
          </select>
        </label>

        <label>
          Anrede *
          <select required v-model="customer.salutation">
            <option
              v-bind:key="salutation"
              v-bind:value="salutation"
              v-for="salutation in salutations"
              >{{ salutation }}</option
            >
          </select>
        </label>
        <label>
          Titel
          <input type="text" v-model="customer.title" />
        </label>
        <label>
          Vorname *
          <input type="text" required v-model="customer.firstname" />
        </label>
        <label>
          Nachname *
          <input type="text" required v-model="customer.lastname" />
        </label>
        <label>
          E-Mail Adresse *
          <input type="text" required v-model="customer.email" />
        </label>
        <label>
          Umsatzsteuer-Id
          <input type="text" v-model="customer.vatid" />
        </label>
        <small>* Felder müssen ausgefüllt werden</small>

        <div class="flex justify-end">
          <button @click="dialog = false">
            Abbrechen
          </button>
          <button :disabled="!valid" class="ml-4" type="submit">
            Anlegen
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    valid: true,
    customergroups: ["Shop Kunde", "Gries Händler", "Gries Endkunde"],
    salutations: ["Herr", "Frau"],
    customer: {
      customernumber: "",
      firstname: "",
      email: "",
      customergroup: "",
      lastname: "",
      title: "",
      salutation: "",
      vatid: "",
      company: ""
    }
  }),
  methods: {
    submit() {
      axios
        .post(`${process.env.VUE_APP_API_URL}/customers.json`, this.customer)
        .then(() => {
          this.dialog = false;
          this.$emit("reload-customers");
        })
        .catch(err => console.error(err));
    }
  },
  props: {
    customerData: Object
  }
};
</script>
