<template>
  <div class="w-full">
    <div>
      Übersicht: Kunden

      <button @click="refreshData" :disabled="disabled" id="refresh-btn">
        <icon-refresh />
      </button>
      <new-customer @reload-customers="loadCustomers" />
    </div>
    <div>
      <input v-model="search" placeholder="Suche" />
    </div>
    <table>
      <thead>
        <tr>
          <td v-for="header in headers" :key="header.text">
            {{ header.text }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="customer in customers" :key="customer.customernumber">
          <td>
            {{ customer.customernumber }}
          </td>
          <td>
            {{ customer.company }}
          </td>
          <td>
            {{ customer.firstname }}
          </td>
          <td>
            {{ customer.lastname }}
          </td>
          <td>
            {{ customer.customergroup }}
          </td>
          <td>{{ customer.requests.length }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import NewCustomer from "@/components/NewCustomer";
import IconRefresh from "@/components/icons/IconRefresh";

export default {
  name: "Customers",
  components: { IconRefresh, NewCustomer },
  data() {
    return {
      edit_dialog: false,
      customers: [],
      customer_to_edit: {},
      disabled: false,
      search: "",
      headers: [
        { text: "Kundennummer", value: "customernumber" },
        { text: "Firma", value: "company" },
        { text: "Vorname", value: "firstname" },
        { text: "Nachname", value: "lastname" },
        {
          text: "Kundengruppe",
          value: "customergroup"
        },
        { text: "Anzahl Anfragen", value: "requests.length" }
      ]
    };
  },
  methods: {
    refreshData() {
      this.disabled = true;
      this.loadCustomers();
      this.$emit("rehydrate data");
      setTimeout(() => {
        this.disabled = false;
      }, 3000);
    },
    editCustomer(e) {
      this.edit_dialog = true;
      console.log(e);
      this.customer_to_edit = e;
    },
    closeDialog() {
      this.edit_dialog = false;
    },
    loadCustomers() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/customers.json`)
        .then(response => {
          this.customers = response.data;
        });
    }
  },
  mounted() {
    this.loadCustomers();
  }
};
</script>
